import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getUser } from '../service/AuthService';
import '../index.css';
import './locationStyle.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { LuAlarmClock } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import AccessInformation from './AccessInformation';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { GoIssueClosed } from 'react-icons/go';

const {
  requestConfig,
  addressUrl,
  compareCoordinatesUrl,
  timeInUrl,
  timeOutUrl,
  displayTableResultsUrl,
  getUserInformationUrl
} = AccessInformation;

// localStorage.clear();
let jobControlTableName;
let suburb;
export default function LocationFinder() {
  const navigate = useNavigate();
  const user = getUser();
  const loginEmail = user !== 'undefined' && user ? user.email : '';
  const name = user !== 'undefined' && user ? user.name : '';

  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const [callusername, setCallUsername] = useState(null);
  const [isClockin, setIsClockin] = useState(false);
  const [isClockout, setIsClockout] = useState(false);
  const [isOnSite, setIsOnSite] = useState(false);
  const [data, setData] = useState([]);

  const [showClockButtons, setShowClockButtons] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading indicator
  const [isLocation, setIsLocation] = useState(false);
  const [delayButton, setDelayButton] = useState(false);

  useEffect(() => {
        // check if isWorking in localStorage is true, then setCalendar to true
        const checkIsWorkingLocal = window.localStorage.getItem('isWorking');
        if (checkIsWorkingLocal === 'true') {
          console.log('set calendar mounted')
          setCalendar(true);
        }
  }, []);

const getUserInformation = async (tableName, email) => {
  try {
    const requestBody = {
      tableName: 'user-info-table',
      email: loginEmail
    }
    const response = await axios.post(getUserInformationUrl, requestBody, requestConfig);
    return response
  } catch (error) {
    console.error(error);
  }
}

  useEffect(() => {

    const timeInLocal = window.localStorage.getItem('TimeIn');
    const workingAddress = window.localStorage.getItem('WorkingAdress');
    const fetchUserInformation = async () => {
      try {
        const checkUser = await getUserInformation('user-info-table', loginEmail);
        console.log('check user:', checkUser);
        // Check if address exists in the returned user info
        const address = checkUser?.data?.response?.dynamoInfo?.address;
        return address || null;  // Return the address if it exists, otherwise null
      } catch (error) {
        console.error('Error fetching user information:', error);
        return null;  // If there's an error, return null
      }
    };

    const handleUserCheck = async () => {
      const userAddress = await fetchUserInformation(); // Await the result of fetchUserInformation
      if (userAddress) {
        console.log('happy');
        console.log(userAddress);
        const filters = {
          email: loginEmail,
          address: userAddress,
          date: dayjs().format('YYYY-MM-DD')
        }
        displayUserInformation('shift-tracking', filters)
        setStatus(null)
        setIsOnSite(true)
        setStatus('You clocked in at: ' + userAddress);
        setCalendar(true);
        const splittedSuburb = userAddress.split(', ');
        suburb = splittedSuburb[splittedSuburb.length - 1].replace(/ /g, "_");
      } else {
        console.log('unhappy');
        setIsOnSite(false)
      }
    };

    handleUserCheck();  // Call handleUserCheck to fetch and check the user information

    // if (timeInLocal) {
    //   // fetchUserInformation();
    //   console.log('time in local is: ', timeInLocal);
    //   setStatus(`Last Punch-In Record: ${timeInLocal} at ${workingAddress}`)
    // }


  }, [])

  let watchId;
  const findMyLocation = (isWorking) => { 
    const success = (position) => {
      
      const latitude = position.coords.latitude //'-33.89964447974515';
      const longitude = position.coords.longitude //'151.1779410467535'
      console.log(latitude + ' ' + longitude)

      const timeOptions = {
        timeZone: "Australia/Sydney",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }

      const date = new Date();
      const formattedDate = `${date.getHours()}:${date.getMinutes()}`;
        let matchFound = false;

          if(isWorking && latitude && longitude) {
            setStatus('');
            // const timeIn = new Date().toLocaleString("en-AU", timeOptions);
            const timeIn = formattedDate;
            console.log('timeIn is: ', timeIn);

            const date = dayjs().format('YYYY-MM-DD')
            
            console.log(date)

            const requestBodyCompareCoordinates = {
              userLat: latitude,
              userLon: longitude,
              name: name,
              time: timeIn
            }

            axios.post(compareCoordinatesUrl, requestBodyCompareCoordinates, requestConfig)
            .then((response) => {
              setStatus('Clocked in successfully at' + '\n' + response.data.message + '\n' + timeIn);
              const splittedData = response.data?.message.split(', ');
              suburb = splittedData[splittedData.length - 1].replace(/ /g, "_");

              console.log("suburb:", suburb);
              // set the suburb for calendar search
              window.localStorage.setItem('suburb', suburb);
              // set the working address
              window.localStorage.setItem('WorkingAdress', response.data.message)
              // set isWorking to be true to keep the calendar button
              window.localStorage.setItem('isWorking', true);
              console.log("local storage " + window.localStorage.getItem('suburb'));
              // set the time in to keep it remain in the app during the shift (get deleted when clocked out)
              window.localStorage.setItem('TimeIn', timeIn);

              const requestBodyTimeIn = {
                email: loginEmail,
                timeIn: timeIn,
                date: date,
                address: response.data.message,
                lonAndLat: longitude + ', ' + latitude
              }

              axios.post(timeInUrl, requestBodyTimeIn, requestConfig)
              .then(response => {
                setIsWorking(true);
                setIsLocation(true);
                setLoading(false);
                setCalendar(true);
                matchFound = true;
                setIsClockin(true);
                setIsClockout(false);
                console.log(isClockin);
                console.log('time in saved')
              }).catch(error => {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    setMessage(error.response.data.message);
                    setStatus(error.response.data.message)
                }
                else {
                    setMessage('Sorry, backend server is down, please try again later')
                }
            })

            }).catch((error) => {
              console.log(error);
              // setStatus(error.response.data.message);
              setStatus(error)
              setLoading(false);
            })


          }
          else if (!isWorking) {
            const timeOut = new Date().toLocaleString("en-AU", timeOptions);
            setStatus('');
            setCalendar(false);
            const requestBodyCompareCoordinates = {
              userLat: latitude,
              userLon: longitude,
              name: name,
              time: timeOut
            }

            axios.post(compareCoordinatesUrl, requestBodyCompareCoordinates, requestConfig)
              .then((response) => {
                const address = response.data.message;

                const requestBodyTimeOut = {
                  email: loginEmail,
                  timeOut: timeOut,
                  address: address
                }

                axios.post(timeOutUrl, requestBodyTimeOut, requestConfig).then(response => {
                  setStatus('You have finished at: ' + timeOut);
                  // delete the localStorage
                  window.localStorage.removeItem('suburb');
                  window.localStorage.removeItem('isWorking');
                  window.localStorage.removeItem('WorkingAdress');
                  window.localStorage.removeItem('TimeIn');

                  setIsWorking(false);
                  setIsLocation(true);
                  setLoading(false);
                  setIsClockout(true);
                  matchFound = true;
                }).catch(error => {
                  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                      setMessage(error.response.data.message);
                      setStatus(error.response.data.message);
                      setLoading(false);
                  }
                  else {
                      setMessage('Sorry, backend server is down, please try again later');
                      setLoading(false);
                  }
                })

              }).catch(error => {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    setMessage(error.response.data.message);
                    setStatus(error.response.data.message);
                    setLoading(false);
                }
                else {
                    setMessage('Sorry, backend server is down, please try again later');
                    setLoading(false);
                }
              })
          }

        
        if (!isLocation) {
          setLoading(false);
          setStatus('Your GPS may be incorrectly tracked, please try again. Your current location: ');
        }
    }

    const errors = () => {
        setStatus('Unble to retrieve your location, please unblock the location permission in your browser');
      }
      navigator.geolocation.getCurrentPosition(success, errors);
    };

    
    const handleClockin = () => {
        findMyLocation(true);
        setLoading(true);

        // handle disable button for 3 secs
        setDelayButton(true);
        setTimeout(() => {
          setDelayButton(false);
        }, 4000);

      };
      
    const handleClockout = () => {
        findMyLocation(false);
        setLoading(true);

        // handle disable button for 3 secs
        setDelayButton(true);
        setTimeout(() => {
          setDelayButton(false);
        }, 4000);
        
    }

    const punchClock = () => {
      setIsWorking(false);
      setCallUsername(loginEmail);

      console.log("Punch Clock clicked " + loginEmail);
      setShowClockButtons((prevShowClockButtons) => !prevShowClockButtons);
    }
   
    const handleCalendar = () => {
      navigate('/calendarsearching', { state: { suburb } });
    }


    
    async function displayUserInformation(tableName, filters) {
      const requestBody = {
        tableName: tableName,
        filters: filters
      }
      axios.post(displayTableResultsUrl, requestBody).then(response => {
        console.log(response.data);
        let columnsToRemove = ['id', 'email', 'password', 'lonAndLat', 'name'];

        const filteredData = response.data.map(item => {
          // Dynamically exclude columns
          const filteredItem = Object.keys(item).reduce((acc, key) => {
            if (!columnsToRemove.includes(key)) {
              acc[key] = item[key]; // Keep the property if it's not in the columnsToRemove array
            }
            return acc;
          }, {});
          return filteredItem;
        });

        setData(filteredData);


      }).catch(error => {
        console.error(error);
      });
    }

    const allKeys = Array.from(
      new Set(data?.flatMap((item) => Object.keys(item)))
    );

  return (
    <section style={{width: "fit-content", height: "fit-content"}}>
      {/* <button className="punch-button" onClick={punchClock}><LuAlarmClock /> Punch Clock</button> */}
    
        {/* <div className={`clockin-clockout ${showClockButtons ? 'active' : 'inactive'}`}> */}
        <div className='clockin-clockout'>
          {/* disabled to be unclickable for 3 secs to reduce traffic */}
          <button className='clockin' onClick={handleClockin} disabled={delayButton} >Clock-in</button>
          <button className='clockout' onClick={handleClockout} disabled={delayButton}>Clock-out</button>
          {loading && (
                <Box sx={{ position: "fixed", top: "40%", left: "45%", zIndex: "1000" }}>
                  <CircularProgress />
                </Box>
            )}
          <p className="status">{status ? status : 'Shift tracker'}</p>
                          
                          {isOnSite === true && (
                            <div>
                              <DataTable
                              value={data}
                              scrollable scrollHeight={'6em'}
                              >
                              {allKeys.map((key) => (
                                <Column
                                  key={key}
                                  field={key}
                                  header={key.toUpperCase()}
                                  style={{margin: "0", padding: "5px"}}
                                  sortable
                                  body={(rowData) =>
                                    rowData[key] && typeof rowData[key] === 'object' ? (
                                      <ul>
                                        {Object.entries(rowData[key]).map(([nestedKey, nestedValue]) => (
                                          <li key={nestedKey}>
                                            {nestedKey}: <strong>{
                                                            nestedValue === true ?
                                                            <GoIssueClosed style={{color: "green", fontSize: '14px'}} /> :
                                                            <IoIosCloseCircleOutline style={{color: "red", fontSize: '14px'}}/>
                                                            }
                                                            </strong>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : (
                                        <>
                                        {/* <button id='copy-button' onClick={() => copyText(rowData[key])}><MdContentCopy /></button> */}
                                        {rowData[key]}
                                        </>
                                    )
                                  }
                                />
                              ))}
                            </DataTable>
                          </div>
                          )}
          <div className={`calendar-button ${calendar ? 'active' : 'inactive'}`}>
              <button className='go-to-calendar-button' onClick={handleCalendar}>Go to Calendar</button>
          </div>
        </div>

    </section>
  );
};

// just for testing to see if the jobControlTableName is valid for public use
const jobTableNameFunction = () => {
  if(jobControlTableName){
    console.log('jobControlTableName is valid')
    return jobControlTableName;
  }
  return "jobControlTableName is invalid"
}
export { jobTableNameFunction }