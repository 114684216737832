import React from "react";
import * as calendarUtils from "./CalendarUtils";

const TaskListDisplay = ({
    tasks,
    taskAndStatus,
    setTaskAndStatus,
    taskType,
    submitTask,
    headerPosition,
    otherParams
}) => {
    if (!tasks?.length) {
        return 
    }
    return (
      <>
        <p style={{ fontWeight: "700"}}>{taskType} tasks:</p>
        {tasks && (
        <>
          {tasks.some(item => item.includes('General Waste')) && '🔴'}
          {tasks.some(item => item.includes('Recycling')) && '🟡'}
          {tasks.some(item => item.includes('FOGO')) && '🟢'}
          {tasks.some(item => item.includes('Food Scraps')) && '🟣'}
          {tasks.some(item => item.includes('Food Waste')) && '🟣'}


        </>
      )}
        {tasks.map((task, index) => {
            const checkboxId = `task-${task}`;
            const isChecked = taskAndStatus[task];
        
            const handleCheckboxChange = () => {
              // Update taskAndStatus map when the checkbox is changed
              const updatedTaskAndStatus = { ...taskAndStatus };
              updatedTaskAndStatus[task] = !isChecked;
              // Set the updated map to your state
              setTaskAndStatus(updatedTaskAndStatus);
            };
          
            return (
              <div>
                <li key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label htmlFor={checkboxId} style={{ flex: 1 }}>{task}</label>
                <input
                  type="checkbox"
                  id={checkboxId}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  style={{ width: '20px', height: '20px', marginLeft: '10px' }}
                />
              </li>
              </div>
              
            )
        })}
        <button
          style={{ position: 'relative', right:'5px', height: '2.5em' }}
          onClick={() => calendarUtils.submitTasks(submitTask, taskAndStatus, headerPosition, otherParams)} >
          Submit
        </button>
    </>
    )
}

export default TaskListDisplay;

// old way in Calendar.jsx file

// const listMonthlyTasks = monthlyTask?.task /*&& isTaskAndStatusReady */ ? (
//   monthlyTask.task.map((task, index) => {
//     const checkboxId = `task-${task}`;
//     const isChecked = monthlyTaskAndStatus[task];

//     const handleCheckboxChange = () => {
//       // Update taskAndStatus map when the checkbox is changed
//       const updatedTaskAndStatus = { ...monthlyTaskAndStatus };
//       updatedTaskAndStatus[task] = !isChecked;
//       // Set the updated map to your state
//       setMonthlyTaskAndStatus(updatedTaskAndStatus);
//     };

//     return (
//       <li key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
//         <label htmlFor={checkboxId} style={{ flex: 1 }}>{task}</label>
//         <input
//           type="checkbox"
//           id={checkboxId}
//           checked={isChecked}
//           onChange={handleCheckboxChange}
//           style={{ width: '20px', height: '20px', marginLeft: '10px' }}
//         />
//       </li>
//     );
//   })
// ) : (
//   <li>No Monthly Tasks Today</li>
// );